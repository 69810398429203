<template>
  <b-modal
    modal-class="component-modal-nextvisit"
    id="NextVisitModal"
    ref="NextVisitModal"
    size="xl fluid"
    title="นัดหมายครั้งต่อไป (Next Visit)"
    hide-footer
    no-close-on-backdrop
    @close="close"
    @hidden="defaultValue"
    @shown="setTableVisible"
  >
    <!-- <div class="d-block d-sm-none text-center">
      <h2>กรุณาหมุนหน้าจอ</h2>
      <i class="fas fa-sync-alt fa-7x"></i>
      <p class="text-muted">(หรือขยายหน้าต่างให้กว้างขึ้น)</p>
    </div>-->
    <div class>
      <div class="row">
        <div
          class="col-12 col-lg-3 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
        >
          <PatientProfile
            :patient="patient"
            button-name="ประวัตินัด"
            :button-click="
              () => {
                openProfileCustomerModal();
              }
            "
          />
        </div>
        <div class="col">
          <b-row>
            <b-col>
              <b-form id="nextVisitForm" @submit.prevent="saveEventAppointment">
                <b-form-row>
                  <b-form-group
                    class="col"
                    label-cols-sm="3"
                    label="นัดหมายเพื่อ:"
                    label-align-sm="right"
                    description="ตั้งรายการใช้ประจำได้ใน 'ลิสต์นัดหมาย'"
                  >
                    <b-input-group>
                      <multiselect
                        class="col p-0"
                        v-model="treatmentList"
                        :options="getGroupedTxList"
                        :multiple="true"
                        group-values="libs"
                        group-label="category"
                        :group-select="false"
                        placeholder="พิมพ์ค้นหารายการนัดหมาย"
                        track-by="nameTh"
                        :custom-label="customLabel"
                        selectLabel="เลือกรายการนัดหมาย"
                        selectedLabel="เลือกแล้ว"
                        deselectLabel="คลิกเพื่อลบออก"
                        :limit="3"
                      >
                        <template #option="props">
                          <span v-if="props.option.$isLabel">
                            {{ props.option.$groupLabel }}
                          </span>
                          <span v-else>
                            <b-button
                              class="mr-1 small"
                              size="xs"
                              variant="outline-info"
                              :pressed="props.option.favorite"
                              @click.stop="favAptToggle(props.option)"
                            >
                              <i class="far fa-star"></i>
                            </b-button>
                            {{
                              props.option.nameEn === props.option.nameTh
                                ? props.option.nameEn
                                : props.option.nameEn +
                                  " / " +
                                  props.option.nameTh
                            }}
                          </span>
                        </template>
                        <span slot="noResult"
                          >ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span
                        >
                      </multiselect>
                      <b-input-group-append>
                        <b-button variant="info" @click="openAptListModal"
                          >ลิสต์นัดหมาย</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
                <b-form-group
                  label-cols-sm="3"
                  label="ระยะเวลารักษา:"
                  label-align-sm="right"
                >
                  <b-input-group class="fixedAppend p-0" append="นาที">
                    <b-form-input
                      class="col"
                      type="range"
                      v-model="durationMin"
                      :min="duration"
                      max="180"
                      :step="duration"
                    ></b-form-input>
                    <b-form-input
                      class="col-2"
                      v-model="durationMin"
                      type="number"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  label-cols-sm="3"
                  label="ระยะห่างการนัด:"
                  label-align-sm="right"
                >
                  <b-input-group class="fixedAppend p-0" append="วัน">
                    <b-form-input
                      class="col"
                      type="range"
                      v-model="slideValue"
                      min="0"
                      :max="daySteps.length - 1"
                      step="1"
                    ></b-form-input>
                    <b-form-input
                      class="col-2"
                      v-model.number="nextApptDay"
                      type="number"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-cols-sm="3"
                  label="วันที่นัด:"
                  label-align-sm="right"
                >
                  <b-input-group>
                    <b-form-datepicker
                      class="normal-weight"
                      :min="new Date()"
                      locale="th"
                      no-flip
                      hide-header
                      v-model="startDt"
                    ></b-form-datepicker>
                  </b-input-group>
                </b-form-group>
                <b-form-row class>
                  <b-form-group
                    class="col mb-0"
                    label="นัดกับแพทย์:"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                    <b-form-select
                      class="required"
                      id="doctor"
                      v-model="doctor"
                      :options="getDoctorList"
                      value-field="uid"
                      text-field="fullName"
                      required
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >รายชื่อแพทย์</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-form-row>
                <hr />

                <b-form-row class="limit-height-col-150 mb-2">
                  <div class="favorite-text-area-container col">
                    <b-form-group
                      description="ข้อมูลที่ต้องการส่งให้เคาน์เตอร์เช่น นัดวันเสาร์ช่วงเช้า เป็นต้น"
                    >
                      <b-form-textarea
                        id="note"
                        ref="nextVisitNote"
                        rows="4"
                        no-resize
                        v-model="note"
                        placeholder="โน๊ตส่งให้เคาน์เตอร์..."
                      ></b-form-textarea>
                    </b-form-group>
                    <FavNoteButton
                      storageName="favNoteNextVisit"
                      @listClicked="handleSelectedFavNote"
                    />
                  </div>
                  <!-- <div class="col-4">
                    <FavNote
                      storageName="favNoteNextVisit"
                      @listClicked="handleSelectedFavNote"
                    />
                  </div> -->
                </b-form-row>
              </b-form>
            </b-col>
          </b-row>

          <!-- Treated table -->
          <b-row>
            <b-col>
              <b-card class="mb-2" body-class="p-2">
                <b-button
                  class
                  size="sm"
                  block
                  variant="outline-info"
                  @click="toggleTable"
                >
                  สรุปรายการรักษาวันนี้
                  <i class="fas fa-chevron-down"></i>
                </b-button>
                <b-collapse id="nvTreatmentTable" v-model="tableVisible">
                  <b-table
                    id="treatmentSummary"
                    ref="treatmentSummary"
                    class="mt-2"
                    small
                    sort-icon-left
                    head-variant="light"
                    :items="treatmentItemList"
                    :fields="fields"
                    show-empty
                  >
                    <template #cell(index)="row">{{ row.index + 1 }}.</template>
                    <template #cell(creationDt)="row">
                      {{ formatDate(row.value) }}
                    </template>
                    <template #row-details="row">
                      <b-card class="mb-1 ml-4 small-text" body-class="p-2">
                        <p v-if="row.item.dxName" class="mb-2">
                          <strong>วินิจฉัย:</strong>
                          {{ row.item.dxName }}
                        </p>
                        <p v-if="row.item.note" class="mb-0">
                          <strong>รายละเอียดการรักษา:</strong>
                          {{ row.item.note }}
                        </p>
                      </b-card>
                    </template>
                    <template #cell(name)="row">
                      {{ row.value }}
                      <b-button
                        v-if="row.item.note || row.item.dxName"
                        class="ml-1"
                        size="sm"
                        variant="outline-info"
                        :pressed="row.detailsShowing"
                        @click="row.toggleDetails"
                      >
                        <i class="far fa-sticky-note"></i>
                      </b-button>
                      <b-button
                        v-if="row.item.drawNote && row.item.drawNote.lines"
                        class="ml-1"
                        size="sm"
                        variant="outline-info"
                        @click="openDisplayDrawingModal(row.item.drawNote)"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </template>
                    <template #empty>
                      <div class="text-center text-muted">
                        <p>ไม่มีรายการรักษา</p>
                      </div>
                    </template>

                    <template #bottom-row></template>
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                  </b-table>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>

          <!-- footer -->
          <p class="text-right description text-muted mb-1 mx-1">
            เมื่อ "ส่งชำระเงิน" ข้อมูล Next visit และรายการรักษาจะถูกบันทึก
            และส่งเข้าคิวชำระเงิน ที่เคาน์เตอร์
          </p>
          <b-row class="d-flex flex-row-reverse">
            <b-button
              class="mr-3"
              type="submit"
              variant="primary"
              form="nextVisitForm"
              >ส่งชำระเงิน</b-button
            >
            <!-- <b-button class="mr-2" variant="danger" @click="defaultValue"
              >รีเซ็ต</b-button
            >-->
            <b-button class="mr-auto ml-3" @click="openPaymentHistoryModal">
              <i class="fas fa-chevron-left"></i>
              <i class="fas fa-chevron-left"></i>กลับไปบันทึกการรักษา
            </b-button>
          </b-row>
        </div>
      </div>
    </div>
    <AppointmentListModal
      ref="appointmentListModal"
      @selectedTreatment="tableSelectedList = $event"
    ></AppointmentListModal>

    <DisplayDrawingModal ref="displayDrawingModal" />

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import AppointmentListModal from "@/components/modal/AppointmentList";
import DisplayDrawingModal from "@/components/modal/DisplayDrawing";
import FavNoteButton from "@/components/button/FavNote";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/NextVisit.scss";

export default {
  name: "NextVisitModal",
  components: {
    FavNoteButton,
    Dialog,
    Loading,
    AppointmentListModal,
    DisplayDrawingModal,
  },
  data() {
    return {
      isLoading: false,
      appointment: null,
      appointmentId: null,
      patient: {},
      resourceId: null,
      aptTreatItems: [],
      note: "",
      durationMin: 15, //config data
      treatmentList: [],
      tableSelectedList: [],
      daySteps: [
        1, 2, 3, 4, 5, 6, 7, 10, 14, 21, 30, 45, 60, 90, 120, 180, 365,
      ],
      slideValue: 6,
      nextApptDay: 7,
      startDt: moment().add(7, "day").format("YYYY-MM-DD"),
      doctor: null,
      appointmentDraft: null,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
          sortable: true,
        },
        {
          key: "doctor",
          label: "แพทย์",
        },
        {
          key: "toothNo",
          label: "ซี่ฟัน",
        },

        {
          key: "name",
          label: "รายการ",
        },

        {
          key: "price",
          label: "ราคา",
          class: "text-right",
        },
        {
          key: "buttons",
          label: "",
          class: "text-right",
        },
      ],
      tableVisible: false,
      treatedList: [],
    };
  },
  computed: {
    ...mapGetters({
      getDoctorList: "moduleUser/getDoctorList",
      getTreatmentList: "moduleAppointment/getTreatmentList",
      getGroupedTxList: "moduleAppointment/getGroupedTxList",
      getTreatmentBy: "moduleAppointment/getTreatmentBy",
      getTreatmentCateBy: "moduleAppointment/getTreatmentCateBy",
      getUserInfo: "moduleUser/getUserInfo",
      getBranchInfo: "moduleUser/getBranchInfo",
      getRoomList: "moduleUser/getRoomList",
      viewAvatar: "moduleContent/viewAvatar",
      getAppointmentFormat: "moduleAppointment/getAppointmentFormat",

      getUserListBy: "moduleUser/getUserListBy",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",
    }),
    treatmentItemList() {
      let allItems = [];
      this.treatedList.forEach(appt => {
        let apptItems = [];

        apptItems = appt.items.map(i => {
          return {
            appooitmentId: appt.apptId,
            treatmentItemId: i.id,
            price: i.price, //amount?
            creationDt: appt.creationDt,
            name: i.name,
            toothNo: i.toothItems
              ? i.toothItems +
                " " +
                this.surfacesFormat(i.toothItems, i.surfaceItems)
              : "",
            doctor: this.getUserListBy(i.actionUid, "uid")?.fullName, //เปลี่ยนจาก actionUid
            quantity: i.quantity,
            note: i.note,
            dxName: this.getTreatDiagBy(i.diagItems, "id")?.nameEn,
            drawNote: i.drawNote,
          };
        });
        allItems.push(...apptItems);
      });
      return allItems;
    },
    duration() {
      return moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      fetchItemsPaymentByAptId: "moduleTreatment/fetchItemsPaymentByAptId",
      fetchQueueAppointmentDraftByApptId:
        "moduleAppointment/fetchQueueAppointmentDraftByApptId",
    }),
    init() {},
    show(appointment, patient) {
      this.$bvModal.show("NextVisitModal");
      this.isLoading = true;
      this.patient = Object.assign({}, patient);
      this.appointment = appointment;
      this.appointmentId = appointment.id;
      let extendedProps = Object.assign(this.appointment.extendedProps);
      // this.patient = Object.assign(extendedProps.patient);
      this.doctor =
        this.getUserInfo.userTypeId === 2 ? this.getUserInfo.uid : null;
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;
      Promise.all([
        this.fetchItemsPaymentByAptId({
          appointmentId: this.appointmentId,
          clinicUrl: clinicUrl,
          branchUrl: branchUrl,
        }),
        this.fetchQueueAppointmentDraftByApptId({
          id: this.appointmentId,
          clinicUrl: clinicUrl,
          branchUrl: branchUrl,
        }),
      ])
        .then(([treatment, aptDraft]) => {
          this.treatedList = treatment.data.treatments;
          this.appointmentDraft = aptDraft.data;
          this.applyInputValue();
        })
        .catch(err => {
          //ถ้าสร้าง appointmentDraft ครั้งแรกจะขึ้น error เนื่องจากสามารถ get ข้อมูลจาก server ได้
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    customLabel(libs) {
      return `${libs.nameTh}-[${libs.nameEn}]`;
    },

    saveEventAppointment() {
      let branchId = this.getBranchInfo.id;
      const startDt = moment().add(this.nextApptDay, "days").format();
      const endDt = moment(startDt).add(this.durationMin, "minutes").format();

      if (this.appointmentDraft) {
        let eventData = {
          // branchId,
          id: this.appointmentDraft.id,
          startDt: startDt,
          endDt: endDt,
          roomId: parseInt(this.resourceId),
          aptTreatItems: this.aptTreatItems,
          doctorUid: this.doctor,
          patientId: this.patient.id,
          note: this.note,
          appointmentId: this.appointmentId,
        };
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/updateQueueAppointmentDraft", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then(res => {
            if (res.status == "200") {
              this.$bvModal.hide("NextVisitModal");
              this.$parent.showDialogToast(
                "success",
                "บันทึกการแก้ไขข้อมูล Next Visit เรียบร้อยแล้ว"
              );
              this.saveWaitingForPayment();
            } else {
              this.$parent.showDialogToast(
                "warning",
                "แก้ไขข้อมูล Next Visit ไม่สำเร็จ"
              );
            }
          })
          .catch(err => {
            console.error(err);
            this.isLoading = false;
            this.$parent.showDialogToast(
              "warning",
              "แก้ไขข้อมูล Next Visit ไม่สำเร็จ"
            );
          });
      } else {
        let eventData = {
          // branchId,
          // id: null,
          startDt: startDt,
          endDt: endDt,
          roomId: parseInt(this.resourceId),
          aptTreatItems: this.aptTreatItems,
          doctorUid: this.doctor,
          patientId: this.patient.id,
          note: this.note,
          appointmentId: this.appointmentId,
        };
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/createQueueAppointmentDraft", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then(res => {
            if (res.status == "200") {
              this.$bvModal.hide("NextVisitModal");
              this.isLoading = false;
              // this.$parent.showDialogToast(
              //   "success",
              //   "บันทึกข้อมูล Next Visit เรียบร้อยแล้ว"
              // );
              this.saveWaitingForPayment();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                "บันทึกข้อมูล Next Visit ไม่สำเร็จ"
              );
            }
          })
          .catch(err => {
            console.error(err);
            this.isLoading = false;
            this.$parent.showDialogToast(
              "warning",
              "บันทึกข้อมูล Next Visit ไม่สำเร็จ"
            );
          });
      }
    },
    openProfileCustomerModal() {
      this.$parent.openProfileCustomerModal(
        this.appointment,
        this.patient,
        true, //hide-footer
        "aptHX"
      );
    },
    saveWaitingForPayment() {
      this.$store
        .dispatch("moduleAppointment/waitingForPaymentAppointment", {
          id: this.appointmentId,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(() => {
          this.$parent.showDialogToast("success", "ส่งชำระเงินเรียบร้อย");
        })
        .catch(err => {
          console.loe(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    close(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.Dialog.showAlertConfirm(
        "ไม่ได้ส่งชำระเงิน",
        "คุณปิดหน้าต่าง Next Visit โดยยังไม่ส่งคนไข้ไปชำระเงิน",
        "warning",
        "ปิดหน้าต่าง",
        "ยกเลิกการปิด"
      ).then(res => {
        if (res.isConfirmed) {
          this.$bvModal.hide("NextVisitModal");
        }
      });
    },
    defaultValue() {
      this.aptTreatItems = [];
      this.note = "";
      this.durationMin = 15;
      this.treatmentList = [];
      this.tableSelectedList = [];
      this.slideValue = 6;
      this.nextApptDay = 7;
      this.doctor = null;
      this.startDt = moment().add(7, "day").format("YYYY-MM-DD");
    },
    openAptListModal() {
      this.$refs.appointmentListModal.show(this.treatmentList);
    },
    openPaymentHistoryModal() {
      this.$bvModal.hide("NextVisitModal");
      this.$parent.openPaymentHistoryModal(this.appointment);
    },
    openDisplayDrawingModal(obj) {
      this.$refs.displayDrawingModal.show(this.patient, obj);
    },
    applyInputValue() {
      if (!this.appointmentDraft) return;
      let treatmentIds = this.appointmentDraft.aptTreatItems || [];
      this.treatmentList = treatmentIds.map(item => {
        return this.getTreatmentBy(item, "id");
      });
      this.$nextTick(() => {
        this.nextApptDay = moment(this.appointmentDraft.startDt)
          .startOf("day")
          .diff(moment().startOf("day"), "day");
        this.doctor = this.appointmentDraft.doctorUid;
        this.note = this.appointmentDraft.note;
        this.durationMin = moment(this.appointmentDraft.endDt).diff(
          this.appointmentDraft.startDt,
          "minute"
        );
      });
    },
    surfacesFormat(tooth, surfaceItems) {
      // let upperAnt = [
      //   "11",
      //   "12",
      //   "13",
      //   "21",
      //   "22",
      //   "23",
      //   "51",
      //   "52",
      //   "53",
      //   "61",
      //   "62",
      //   "63",
      // ];
      // let lowerAnt = [
      //   "31",
      //   "32",
      //   "33",
      //   "41",
      //   "42",
      //   "43",
      //   "71",
      //   "72",
      //   "73",
      //   "81",
      //   "82",
      //   "83",
      // ];
      // let upperPost = [
      //   "14",
      //   "15",
      //   "16",
      //   "17",
      //   "18",
      //   "24",
      //   "25",
      //   "26",
      //   "27",
      //   "28",
      //   "54",
      //   "55",
      //   "64",
      //   "65",
      // ];
      // let lowerPost = [
      //   "34",
      //   "35",
      //   "36",
      //   "37",
      //   "38",
      //   "44",
      //   "45",
      //   "46",
      //   "47",
      //   "48",
      //   "74",
      //   "75",
      //   "84",
      //   "85",
      // ];
      // let surfaces;
      // if (0 <= upperAnt.indexOf(tooth)) {
      let surfaces = [
        { id: 5, label: "O", order: 1 },
        { id: 3, label: "M", order: 2 },
        { id: 4, label: "D", order: 3 },
        { id: 1, label: "B", order: 4 },
        { id: 2, label: "Li", order: 5 },
      ];
      //   };
      // } else if (0 <= lowerAnt.indexOf(tooth)) {
      //   surfaces = {
      //     1: "La",
      //     2: "Li",
      //     3: "M",
      //     4: "D",
      //     5: "I",
      //   };
      // } else if (0 <= upperPost.indexOf(tooth)) {
      //   surfaces = {
      //     1: "B",
      //     2: "P",
      //     3: "M",
      //     4: "D",
      //     5: "O",
      //   };
      // } else if (0 <= lowerPost.indexOf(tooth)) {
      //   surfaces = {
      //     1: "B",
      //     2: "Li",
      //     3: "M",
      //     4: "D",
      //     5: "O",
      //   };
      // } else {
      //   surfaces = {};
      // }

      let surfacesTx =
        surfaceItems
          ?.map(i => surfaces.find(j => j.id === i))
          .sort((a, b) => a.order - b.order)
          .map(i => i.label)
          .join("") || "";
      return surfacesTx ? surfacesTx : "";
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    toggleTable() {
      this.tableVisible = !this.tableVisible;
      localStorage.setItem("nvTableVisible", this.tableVisible);
    },
    setTableVisible() {
      this.tableVisible = localStorage.getItem("nvTableVisible") === "true";
    },
    favAptToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleSelectedFavNote(e) {
      if (!this.note.trim()) {
        this.note = e;
      } else {
        this.note += "\n" + e;
      }
      this.$refs.nextVisitNote.focus();
    },
  },
  watch: {
    tableSelectedList() {
      this.treatmentList = this.tableSelectedList;
    },
    treatmentList() {
      this.durationMin = 0;
      let selectedOptions = [];
      this.treatmentList.forEach(val => {
        this.durationMin += val.durationMin;
        selectedOptions.push(val.id);
      });
      this.aptTreatItems = selectedOptions;
      if (this.durationMin < this.duration) {
        this.durationMin = this.duration;
      }
      if (this.treatmentList.length > 0) {
        this.nextApptDay = this.treatmentList[0].nextApptDay;
        // let day = this.treatmentList.map((item) => item.nextApptDay);
        // this.nextApptDay = Math.min(...day);
      }
    },
    slideValue() {
      this.nextApptDay = this.daySteps[this.slideValue];
    },
    nextApptDay() {
      if (this.daySteps.indexOf(this.nextApptDay) === -1) {
        return;
      }
      this.slideValue = this.daySteps.indexOf(this.nextApptDay);
      this.startDt = moment().add(this.nextApptDay, "days").format();
    },
    startDt(val) {
      this.nextApptDay = moment(val).diff(moment().startOf("day"), "day");
    },
  },
};
</script>

<style>
.normal-weight label:not(.form-check-label):not(.custom-file-label) {
  font-weight: normal;
}
</style>
