<template>
  <b-modal
    id="DisplayDrawing"
    ref="DisplayDrawing"
    modal-class="component-modal-display-drawing"
    title="บันทึกการรักษา"
    :static="true"
    size="fullscreen"
    no-close-on-backdrop
    hide-header
    hide-footer
    @show="defaultValue"
  >
    <button class="close float-right" @click="close">×</button>
    <v-stage
      id="stage"
      ref="stage"
      :config="configKonva"
      @touchmove="startMove"
      @touchend="endMove"
    >
      <v-layer ref="backgroundLayer">
        <v-rect :config="configBackground"></v-rect>
      </v-layer>
      <v-layer ref="layer">
        <v-image
          :config="{
            image: image,
          }"
        />
        <v-text :config="configText"></v-text>
        <v-line v-for="line in lines" :key="line.id" :config="line"></v-line>
      </v-layer>
    </v-stage>

    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import Konva from "konva";
Konva.hitOnDragEnabled = false;
Konva.captureTouchEventsEnabled = false;

import Vue from "vue";
import VueKonva from "vue-konva";
Vue.use(VueKonva);

import { mapGetters, mapActions } from "vuex";

import Dialog from "@/components/modal/Dialog";

import noteTemplate from "@/assets/img/noteTemplate.jpeg";

import "@/theme/modal/DisplayDrawing.scss";

export default {
  name: "UserManage",
  components: {
    Dialog,
  },
  data() {
    return {
      configKonva: {
        width: 720,
        height: 720,
        draggable: true,
      },

      configBackground: {
        x: 0,
        y: 0,
        width: 720,
        height: 720,
        fillPatternImage: null,
      },
      configText: {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      },
      lines: [],

      image: null,

      //Konva objects
      stage: null,
      layer: null,
      backgroundLayer: null,

      lastCenter: null,
      lastDist: 0,

      patient: null,
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      getUserInfo: "moduleUser/getUserInfo",
    }),
    drawObj: {
      get() {
        return { configText: this.configText, lines: this.lines };
      },
      // set(obj) {
      //   obj.lines? this.lines = obj.lines: this.lines = [];
      // },
    },
  },
  methods: {
    show(patient, drawObj) {
      this.$bvModal.show("DisplayDrawing");
      this.$nextTick(() => {
        this.patient = patient;
        this.lines = drawObj.lines;
        this.configText = drawObj.configText;
      });
    },
    close() {
      this.$bvModal.hide("DisplayDrawing");
    },

    //zoom
    getDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },
    getCenter(p1, p2) {
      return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
      };
    },

    startMove(e) {
      e.evt.preventDefault();
      this.stage.stopDrag();
      var touch1 = e.evt.touches[0];
      var touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        if (this.stage.isDragging()) {
          this.stage.stopDrag();
        }

        var p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        var p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!this.lastCenter) {
          this.lastCenter = this.getCenter(p1, p2);
          return;
        }
        var newCenter = this.getCenter(p1, p2);

        var dist = this.getDistance(p1, p2);

        if (!this.lastDist) {
          this.lastDist = dist;
        }

        // local coordinates of center point
        var pointTo = {
          x: (newCenter.x - this.stage.x()) / this.stage.scaleX(),
          y: (newCenter.y - this.stage.y()) / this.stage.scaleX(),
        };

        var scale = this.stage.scaleX() * (dist / this.lastDist);

        this.stage.scaleX(scale);
        this.stage.scaleY(scale);

        // calculate new position of the stage
        var dx = newCenter.x - this.lastCenter.x;
        var dy = newCenter.y - this.lastCenter.y;

        var newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        this.stage.position(newPos);
        this.stage.batchDraw();

        this.lastDist = dist;
        this.lastCenter = newCenter;
      }
    },
    endMove(e) {
      this.lastDist = 0;
      this.lastCenter = null;
    },

    //export file
    exportToPNG() {
      if (this.lines.length === 0) {
        this.$refs.Dialog.showAlertInfo("ไม่มีข้อมูลให้บันทึก");
        return;
      } else {
        let dataURL = this.stage.toDataURL({
          mimeType: "image/png",
          pixelRatio: 2,
        });
        this.downloadURI(dataURL, "EMR.png");
      }
    },
    downloadURI(uri, name) {
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    defaultValue() {
      this.lines = [];
      this.configText = {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      };
      this.image = null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.stage = this.$refs.stage.getNode();
      this.layer = this.$refs.layer.getNode();

      const image = new window.Image();
      image.src = noteTemplate;
      image.onload = () => {
        this.configBackground.fillPatternImage = image;
      };
    });
  },
};
</script>

<style>
</style>